.validation-field-error {
	margin-top: -3.5rem;
	height: 4rem;
    font-size: 1rem;
    color: #ec5840;
	@media (max-width: 767px) {
		height: 6rem;
		margin-top: -6rem;
		font-size: 2rem;
	}
}
.material-input label{
	font-weight: 300;
}
