@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700);
html {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: calc((1vh + 1vw) / 2); }

body {
  margin: 0;
  padding: 0;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain; }

.App {
  text-align: left;
  box-sizing: border-box;
  height: 100vh;
  position: relative; }

.intro-background {
  position: absolute;
  z-index: 200;
  background: #67A5BA;
  -webkit-animation: fadeDown 800ms linear 3200ms forwards;
          animation: fadeDown 800ms linear 3200ms forwards;
  opacity: 1;
  height: 100vh;
  width: 100%;
  pointer-events: none; }

@-webkit-keyframes fadeDown {
  100% {
    opacity: 0; } }

@keyframes fadeDown {
  100% {
    opacity: 0; } }

.validation-field-error {
  margin-top: -3.5rem;
  height: 4rem;
  font-size: 1rem;
  color: #ec5840; }
  @media (max-width: 767px) {
    .validation-field-error {
      height: 6rem;
      margin-top: -6rem;
      font-size: 2rem; } }

.material-input label {
  font-weight: 300; }

.contact-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .contact-form.blur {
    opacity: 0.85;
    -webkit-filter: blur(3px);
            filter: blur(3px);
    pointer-events: none; }
    .contact-form.blur .form-button {
      background-color: transparent;
      color: #67a5ba;
      border: none; }
  @media (max-width: 767px) {
    .contact-form {
      margin-bottom: 7vh; } }
  .contact-form .material-input {
    display: flex; }
    .contact-form .material-input input,
    .contact-form .material-input .textarea-content {
      flex-grow: 1;
      border: none;
      outline: none;
      font-size: 1.25em;
      font-weight: 300;
      color: #595959;
      font-family: "Roboto Condensed", sans-serif;
      background: none;
      border-bottom: 1px solid #595959;
      margin: 0 0 4rem; }
      @media (max-width: 767px) {
        .contact-form .material-input input,
        .contact-form .material-input .textarea-content {
          font-size: 3.5em;
          margin: 0 0 7rem; } }
    .contact-form .material-input input {
      padding: 0.5rem 0;
      height: 1em;
      border-radius: 0; }
    .contact-form .material-input .textarea-content {
      height: 7em;
      resize: none;
      padding: 0.5em 0; }
      @media (max-width: 767px) {
        .contact-form .material-input .textarea-content {
          height: 6em;
          border-radius: 0; } }
  .contact-form .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    @media (max-width: 767px) {
      .contact-form .button-container {
        margin-top: 7.25em;
        justify-content: center; } }
    .contact-form .button-container .form-button {
      width: 6em;
      height: 2.5em;
      font-size: 1.5em;
      font-weight: 300;
      letter-spacing: 1px; }
      @media (max-width: 350px) and (orientation: portrait) {
        .contact-form .button-container .form-button {
          width: 10em;
          height: 2.2em;
          font-size: 4.5em; } }
      @media (max-width: 767px) {
        .contact-form .button-container .form-button {
          height: 2.5em;
          font-size: 3.5em; } }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .contact-form .button-container .form-button {
          width: 10em;
          height: 3em;
          font-size: 1.725em; } }
  .contact-form input:focus, .contact-form input:hover, .contact-form input:active,
  .contact-form .textarea-content:focus,
  .contact-form .textarea-content:hover,
  .contact-form .textarea-content:active {
    outline: none;
    border: none;
    border-bottom: 1px solid #67a5ba; }

.form-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 5.7em; }
  @media all and (min-width: 667px) and (max-width: 1280px) {
    .form-container {
      margin-top: 7.6em; } }
  @media (max-width: 767px) {
    .form-container {
      margin-top: 6.2em;
      width: 100%; } }
  @media (max-width: 767px) and (orientation: landscape) {
    .form-container {
      top: 11.2em;
      right: 4.6em;
      font-size: 1em;
      width: 91%; } }
  @media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    .form-container {
      margin-top: 7.25em;
      width: 90%; } }
  @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
    .form-container {
      margin-top: 7.25em; } }
  .form-container .send-overlay {
    position: absolute;
    top: 22em;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px) {
      .form-container .send-overlay {
        bottom: 6em; } }
    .form-container .send-overlay .sending-step {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .form-container .send-overlay .sending-step .spinner {
        margin-bottom: 1em; }
      .form-container .send-overlay .sending-step span {
        font-size: 1.25em;
        color: #67a5ba; }
        @media (max-width: 767px) {
          .form-container .send-overlay .sending-step span {
            font-size: 3em; } }
    .form-container .send-overlay .sent-step {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .form-container .send-overlay .sent-step .success-icon-container {
        margin-bottom: 0.5em; }
      .form-container .send-overlay .sent-step .success-text {
        font-size: 1.25em;
        color: #7ec9ba;
        margin-bottom: 1em; }
        @media (max-width: 767px) {
          .form-container .send-overlay .sent-step .success-text {
            font-size: 3em; } }

.locations-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-top: 2em; }
  @media (max-width: 767px) {
    .locations-container {
      font-size: 2em;
      margin-bottom: 15em; } }
  .locations-container .title-locations {
    font-size: 2.5em;
    font-weight: 700;
    color: #595955;
    margin-top: 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .locations-container .address {
    color: #595955;
    font-size: 1.5em;
    font-weight: 300; }
    .locations-container .address:not(:last-child) {
      margin-bottom: 1em; }

@charset "UTF-8";
.contact-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex-grow: 1; }
  @media (min-width: 767px) and (max-width: 1280px) {
    .contact-page-container .text-form {
      margin-right: 11.8em; } }
  .contact-page-container .main-container {
    display: flex;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 767px) {
      .contact-page-container .main-container {
        flex-direction: column; } }
    @media (min-width: 767px) {
      .contact-page-container .main-container {
        height: 50vh; } }
    .contact-page-container .main-container .text-form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: #595959;
      margin-right: 6em; }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .contact-page-container .main-container .text-form {
          width: 30%; } }
      @media (max-width: 767px) {
        .contact-page-container .main-container .text-form {
          margin-right: 0;
          margin-top:  3 0.75em; } }
      .contact-page-container .main-container .text-form .title {
        font-size: 1.875em;
        font-weight: 700;
        margin-top: 3.3em;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none; }
        @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
          .contact-page-container .main-container .text-form .title {
            margin-bottom: 0.75em;
            margin-top: 1.5em; } }
        @media (min-width: 320px) and (max-width: 568px), (max-width: 767px) and (orientation: landscape) {
          .contact-page-container .main-container .text-form .title {
            margin: 1.25em 0 1em 0; } }
        @media (max-width: 767px) {
          .contact-page-container .main-container .text-form .title {
            font-size: 4.7em;
            margin-top: 2.5em;
            margin-bottom: 0.6em; } }
        @media all and (min-width: 767px) and (max-width: 1280px) {
          .contact-page-container .main-container .text-form .title {
            font-size: 2.5em; } }
      .contact-page-container .main-container .text-form .description {
        max-width: 30em;
        font-size: 1.25em;
        line-height: 1.75em;
        font-weight: 300;
        margin: 0;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none; }
        @media (max-width: 767px) {
          .contact-page-container .main-container .text-form .description {
            font-size: 3.5em;
            line-height: 1.5; } }
        @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
          .contact-page-container .main-container .text-form .description {
            margin: 0; } }
        @media all and (min-width: 767px) and (max-width: 1280px) {
          .contact-page-container .main-container .text-form .description {
            font-size: 2em;
            line-height: 1.5em; } }
        .contact-page-container .main-container .text-form .description a {
          color: #67A5BA; }

.description-page {
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media (min-width: 667px) and (max-width: 767px) {
    .description-page {
      max-width: 80%; } }
  @media (max-width: 767px) and (orientation: landscape) {
    .description-page {
      max-width: 88%; } }
  @media all and (min-width: 767px) and (max-width: 1280px) {
    .description-page {
      max-width: 80%; } }
  @media (max-width: 425px) and (min-height: 800px) {
    .description-page {
      font-size: 0.9em; } }
  .description-page .titles-container {
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
    white-space: pre; }
    @media (max-width: 767px) {
      .description-page .titles-container {
        margin-top: 3vh; } }
    .description-page .titles-container .main-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-size: 5em;
      font-weight: 700;
      line-height: 1.25em;
      color: #fff;
      white-space: pre-line;
      margin: 0; }
      @media (min-width: 1280px) {
        .description-page .titles-container .main-title {
          max-width: 80%; } }
      @media (max-width: 767px) and (orientation: landscape) {
        .description-page .titles-container .main-title {
          max-width: 100%; } }
      @media (max-width: 767px) and (orientation: portrait) {
        .description-page .titles-container .main-title {
          font-size: 6em;
          line-height: 1.3em;
          min-width: 300px; } }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .description-page .titles-container .main-title {
          font-size: 6em; } }
      @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
        .description-page .titles-container .main-title .main-text {
          width: 90%; } }
    .description-page .titles-container .titles-divider {
      height: 0;
      border-top: 1.2px solid #fff;
      margin: 5vh 0;
      padding: 0;
      width: 7em; }
      @media (min-width: 320px) and (max-width: 568px), (max-width: 767px) and (orientation: landscape) {
        .description-page .titles-container .titles-divider {
          margin: 3.25em 0; } }
      @media (max-width: 767px) {
        .description-page .titles-container .titles-divider {
          margin: 4vh 0;
          width: 22em; } }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .description-page .titles-container .titles-divider {
          width: 26em; } }
    .description-page .titles-container .subtext-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      white-space: pre-wrap;
      font-size: 1.875em;
      line-height: 1.75em;
      font-weight: 300;
      margin: 0; }
      @media (max-width: 767px) {
        .description-page .titles-container .subtext-container {
          font-size: 4em;
          line-height: 1.4em; } }
      @media (min-width: 1280px) {
        .description-page .titles-container .subtext-container {
          max-width: 80%; } }
      @media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
        .description-page .titles-container .subtext-container {
          padding-right: 8vw; } }
      @media (max-width: 767px) and (orientation: landscape) {
        .description-page .titles-container .subtext-container {
          padding-right: 5vw; } }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .description-page .titles-container .subtext-container {
          font-size: 3em; } }

.dynamic-page {
  width: 100vw;
  height: 100vh;
  padding: 16vh calc(6vh + 8em) 0 calc(6vh + 8em);
  box-sizing: border-box; }
  @media (min-width: 767px) and (max-width: 1280px) {
    .dynamic-page {
      padding: 16vh 6vh 0 6vh; } }
  @media (max-width: 767px) {
    .dynamic-page {
      padding: 16vh 4vh 0 4vh; } }
  @media (max-width: 767px) and (orientation: landscape) {
    .dynamic-page {
      padding: 16vh 6vh 0 6vh; } }
  @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
    .dynamic-page {
      padding: 16vh 4vh 0 4vh; } }
  .dynamic-page .current-section {
    font-size: 1.25em;
    font-weight: 700;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 0; }
    @media all and (min-width: 767px) and (max-width: 1280px) {
      .dynamic-page .current-section {
        font-size: 2.25em;
        margin-left: 5.1em; } }
    @media (max-width: 767px) {
      .dynamic-page .current-section {
        margin-left: 5.7em;
        font-size: 3.5em;
        margin-top: 0; } }
    @media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
      .dynamic-page .current-section {
        margin-top: 0; } }
  @media (max-width: 767px) and (orientation: landscape) {
    .dynamic-page .titles-container {
      margin-top: 5vh; } }
  @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
    .dynamic-page .subtext {
      width: 90%; } }
  .dynamic-page-0 {
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none; }
    .dynamic-page-0 .titles-container {
      color: #fff; }
    .dynamic-page-0 .current-section {
      color: #fff; }
    .dynamic-page-0 .line-container {
      color: #fff; }
    @media (min-width: 1600px) {
      .dynamic-page-0 .main-text {
        width: 66%; } }
    @media all and (min-width: 767px) and (max-width: 1500px) {
      .dynamic-page-0 .main-text {
        width: 66%; } }
  .dynamic-page-1 {
    pointer-events: none; }
    .dynamic-page-1 .titles-container .main-title {
      color: #67a5ba; }
    .dynamic-page-1 .titles-container .subtext-container {
      color: #595959; }
    .dynamic-page-1 .current-section {
      color: #595959; }
    .dynamic-page-1 .line-container {
      color: #595959; }
    .dynamic-page-1 .titles-divider {
      border-top: 1.2px solid #595959 !important; }
  .dynamic-page-2 {
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 767px) and (max-width: 1280px) {
      .dynamic-page-2 .main-title {
        max-width: 85%; } }
    @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
      .dynamic-page-2 .main-title, .dynamic-page-2 .subtext-container {
        min-width: 280px; } }
    .dynamic-page-2 .titles-container {
      color: #fff; }
    .dynamic-page-2 .current-section {
      color: #fff; }
    .dynamic-page-2 .line-container {
      color: #fff; }
    @media (min-width: 1600px) {
      .dynamic-page-2 .main-text {
        width: 90%; } }
  .dynamic-page-3 {
    pointer-events: none; }
    .dynamic-page-3 .titles-container {
      color: #fff; }
    .dynamic-page-3 .current-section {
      color: #fff; }
    .dynamic-page-3 .line-container {
      color: #fff; }
    @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
      .dynamic-page-3 .subtext-container {
        min-width: 280px; } }
  .dynamic-page-4 .current-section,
  .dynamic-page-4 .icons-container span,
  .dynamic-page-4 a {
    color: #595959; }
  @media (max-width: 767px) {
    .dynamic-page-4 {
      height: auto; } }

@media (min-width: 768px) {
  .icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 2.625em; }
    .icons-container .icon-container {
      width: 2.625em;
      height: 3.5em;
      display: flex;
      align-items: center;
      justify-content: center; } }
    @media (min-width: 768px) and (max-width: 767px) and (orientation: landscape) {
      .icons-container .icon-container {
        justify-content: space-between;
        margin: 2em 0; } }

@media (min-width: 768px) {
      .icons-container .icon-container .icon-link {
        margin: 0.875em 0;
        transition: 0.4s; } }
      @media only screen and (min-width: 768px) and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
        .icons-container .icon-container .icon-link {
          margin: 3em 0; } }

@media (min-width: 768px) {
        .icons-container .icon-container .icon-link:hover {
          margin: 0.3125em 0; }
        .icons-container .icon-container .icon-link .icon-img {
          font-size: 2em;
          transition: 0.4s; }
          .icons-container .icon-container .icon-link .icon-img:hover {
            font-size: 3.125em; } }
        @media only screen and (min-width: 768px) and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
          .icons-container .icon-container .icon-link .icon-img {
            font-size: 3.5em; } }

@media (max-width: 767px) {
  .icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    justify-content: space-between;
    height: 10em;
    width: 100%; }
    .icons-container .icon-link {
      margin: 0 0.875em;
      transition: 0.4s; }
      .icons-container .icon-link:hover {
        margin: 0 0.3125em; }
      .icons-container .icon-link .icon-img {
        font-size: 3em;
        transition: 0.4s; }
        .icons-container .icon-link .icon-img:hover {
          font-size: 4em; } }
      @media (max-width: 767px) and (max-width: 767px) and (orientation: portrait) {
        .icons-container .icon-link .icon-img {
          font-size: 4em; } }

@media (max-width: 767px) and (orientation: landscape) {
  .icons-container {
    flex-direction: column;
    align-self: center; }
    .icons-container .icon-container {
      margin: 2.5em 0; }
      .icons-container .icon-container .icon-img {
        font-size: 4.5em; } }

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icons.460fc68f.eot);
  src: url(/static/media/icons.460fc68f.eot) format("embedded-opentype"), url(/static/media/icons.c7d59021.ttf) format("truetype"), url(/static/media/icons.0c91c3d0.woff) format("woff"), url(/static/media/icons.ca75658d.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow:before {
  content: "\E906";
  color: #fff; }

.icon-success {
  color: white;
  background: #7EC9BA;
  border-radius: 1em;
  font-size: 1.6em;
  font-weight: 700; }
  @media (max-width: 767px) {
    .icon-success {
      font-size: 4em; } }

.icon-success:before {
  content: "\E904"; }

.icon-email:before {
  content: "\E901"; }

.icon-github:before {
  content: "\E902"; }

.icon-linkedin:before {
  content: "\E903"; }

.icon-twitter:before {
  content: "\E905"; }

.arrow-container {
  transition: background-color 1000ms ease-in-out;
  height: 3.75em;
  width: 3.75em;
  border-radius: 50%;
  background-color: #67A5BA;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all; }

.icon-arrow {
  font-size: 1.5em;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  height: 1.25em; }
  .icon-arrow.inverted {
    -webkit-transform: rotate(180deg) translateY(10%);
            transform: rotate(180deg) translateY(10%); }

.icon-arrow:before {
  transition: color 500ms ease-in-out;
  content: "\E906";
  color: #fff; }

* {
  outline: none; }

a {
  text-decoration: none; }

.fixed-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .fixed-container .page-container {
    display: flex;
    margin-top: 16vh;
    margin-left: 6vh; }
    @media (max-width: 767px) {
      .fixed-container .page-container {
        margin-left: 4vh; } }
    .fixed-container .page-container .number-page {
      font-size: 1.25em;
      font-weight: 700;
      display: inline-block;
      transition: color 100ms ease-in-out; }
      @media (min-width: 768px) and (max-width: 1280px) {
        .fixed-container .page-container .number-page {
          font-size: 2.25em; } }
      @media (max-width: 767px) {
        .fixed-container .page-container .number-page {
          font-size: 3.5em; } }
      @media (max-width: 767px) and (orientation: landscape) {
        .fixed-container .page-container .number-page {
          margin-left: 0.4em; } }
    .fixed-container .page-container .page-line {
      border-top: 1px solid;
      height: 1px;
      align-self: center;
      margin-left: 0.5em;
      width: 5em; }
      @media (min-width: 768px) and (max-width: 1280px) {
        .fixed-container .page-container .page-line {
          width: 7em; } }
      @media (max-width: 767px) {
        .fixed-container .page-container .page-line {
          width: 14em; } }
  .fixed-container .bar-icons-container {
    position: fixed;
    pointer-events: all; }
    @media (min-width: 768px) {
      .fixed-container .bar-icons-container {
        right: 4.5em;
        top: 10em; } }
    @media (max-width: 767px) {
      .fixed-container .bar-icons-container {
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: 2em; }
        .fixed-container .bar-icons-container > div {
          width: 30em; } }
    @media (min-height: 1024px) and (max-height: 1366px) {
      .fixed-container .bar-icons-container {
        top: 12.5em; } }
    @media (max-width: 767px) and (orientation: landscape) {
      .fixed-container .bar-icons-container {
        width: 6em;
        right: 0;
        top: 32%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }
  .fixed-container .arrow-line-container {
    position: fixed;
    right: 4em;
    bottom: 3.75em;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60%;
    justify-content: flex-end; }
    @media (min-width: 320px) and (max-width: 568px), (max-width: 767px) and (orientation: landscape) {
      .fixed-container .arrow-line-container {
        display: none; } }
    @media (max-height: 500px), (max-width: 767px) {
      .fixed-container .arrow-line-container {
        display: none; } }
    .fixed-container .arrow-line-container .vertical-line {
      transition: color 100ms ease-in-out;
      width: 0;
      border-left: 1px solid;
      height: 20em;
      margin-bottom: 1.25em; }
      @media (max-height: 590px), (max-width: 767px) {
        .fixed-container .arrow-line-container .vertical-line {
          display: none; } }
  .fixed-container-0 .number-page {
    color: #fff; }
  .fixed-container-0 .icon-link {
    color: #fff; }
  .fixed-container-0 .vertical-line.vertical-line {
    border-left-color: #fff; }
  .fixed-container-0 .page-line.page-line {
    border-top-color: #fff; }
  .fixed-container-1 .number-page {
    color: #595959; }
  .fixed-container-1 .icon-link {
    color: #595959; }
  .fixed-container-1 .vertical-line.vertical-line {
    border-left-color: #595959; }
  .fixed-container-1 .app-header {
    color: #67a5ba; }
  .fixed-container-1 .page-line.page-line {
    border-top-color: #595959; }
  .fixed-container-2 .number-page {
    color: #fff; }
  .fixed-container-2 .icon-link {
    color: #fff; }
  .fixed-container-2 .vertical-line.vertical-line {
    border-left-color: #fff; }
  .fixed-container-2 .page-line.page-line {
    border-top-color: #fff; }
  .fixed-container-3 .arrow-container {
    background-color: #fff; }
  .fixed-container-3 .icon-arrow:before {
    color: #67a5ba; }
  .fixed-container-3 .number-page {
    color: #fff; }
  .fixed-container-3 .icon-link {
    color: #fff; }
  .fixed-container-3 .vertical-line.vertical-line {
    border-left-color: #fff; }
  .fixed-container-3 .page-line.page-line {
    border-top-color: #fff; }
  .fixed-container-4 .number-page {
    color: #595959; }
  .fixed-container-4 .icon-link {
    color: #595959; }
  .fixed-container-4 .vertical-line.vertical-line {
    border-left-color: #595959; }
  .fixed-container-4 .page-line.page-line {
    border-top-color: #595959; }
  .fixed-container-4 .icon-link {
    color: #595959; }
  @media (max-width: 767px) and (orientation: portrait) {
    .fixed-container-4 .bar-icons-container {
      display: none; } }

.page-scroller {
  height: 100vh;
  width: 100vw; }

.background-container {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  transition: opacity 300ms ease-in-out;
  opacity: 0; }
  .background-container.active {
    opacity: 1; }
  .background-container-0 {
    background-color: #71AFBF;
    background-image: url(/static/media/NY.acacaee0.jpg); }
  .background-container-1 {
    background: #fff; }
  .background-container-2 {
    background-color: #71AFBF;
    background-image: url(/static/media/buenos_aires.64f3934c.jpg); }
  .background-container-3 {
    background: #71AFBF; }
  .background-container-4 {
    background: #fff; }
  .background-container-0, .background-container-2 {
    background-repeat: no-repeat;
    background-size: cover; }

.app-header {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  position: fixed;
  top: 5vh;
  transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms;
  align-items: center;
  height: 11vh;
  z-index: 1000; }
  .app-header-entering {
    top: 50vh; }
  .app-header svg {
    height: 2.5em; }
    @media (max-width: 767px) {
      .app-header svg {
        height: 3.5em; } }
  @media (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    .app-header .app-header {
      margin-top: 3.5em; }
    .app-header svg {
      height: 4.5em; } }
  .app-header.scrolled {
    background: #ffffff; }

.logo {
  cursor: pointer;
  -webkit-transform: scale(5);
          transform: scale(5);
  transition: -webkit-transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms;
  transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms;
  transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms, -webkit-transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms; }
  @media (max-width: 667px) {
    .logo {
      -webkit-transform: scale(3);
              transform: scale(3); } }
  .logo-entered {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .logo .b-logo {
    -webkit-transform: translateX(45%);
            transform: translateX(45%);
    fill-opacity: 0;
    transition: -webkit-transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    stroke: #fff;
    stroke-width: 0.5px;
    stroke-dasharray: 1932 1934;
    stroke-dashoffset: 1933;
    -webkit-animation: draw_border 3500ms ease-in, gradientFill 600ms linear 1000ms;
            animation: draw_border 3500ms ease-in, gradientFill 600ms linear 1000ms; }
    .logo .b-logo-entered {
      fill-opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
  .logo .bluealba-word {
    opacity: 0;
    transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms; }
    .logo .bluealba-word-entered {
      opacity: 1; }

@-webkit-keyframes draw_border {
  0% {
    stroke-dashoffset: 1933; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes draw_border {
  0% {
    stroke-dashoffset: 1933; }
  100% {
    stroke-dashoffset: 0; } }

@-webkit-keyframes gradientFill {
  0% {
    fill-opacity: 0;
    fill: #fff; }
  100% {
    fill-opacity: 1;
    fill: #fff; } }

@keyframes gradientFill {
  0% {
    fill-opacity: 0;
    fill: #fff; }
  100% {
    fill-opacity: 1;
    fill: #fff; } }

.footer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: visible;
  justify-self: flex-end; }
  @media (max-width: 767px) {
    .footer-container {
      margin-top: 8em; } }
  .footer-container .footer-icons {
    margin-top: 5em;
    width: 30em;
    align-self: center; }
    @media (max-width: 767px) and (orientation: landscape) {
      .footer-container .footer-icons {
        display: none; } }
  .footer-container .copyright {
    pointer-events: none;
    display: flex;
    justify-content: center;
    color: #595959;
    margin-top: 3.75em;
    padding: 2.5em 0 2.5em 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center; }
    @media (max-width: 767px) {
      .footer-container .copyright {
        font-size: 2em;
        padding: 2em 0; } }

