@charset "UTF-8";
.contact-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  flex-grow: 1; }
  @media (min-width: 767px) and (max-width: 1280px) {
    .contact-page-container .text-form {
      margin-right: 11.8em; } }
  .contact-page-container .main-container {
    display: flex;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 767px) {
      .contact-page-container .main-container {
        flex-direction: column; } }
    @media (min-width: 767px) {
      .contact-page-container .main-container {
        height: 50vh; } }
    .contact-page-container .main-container .text-form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: #595959;
      margin-right: 6em; }
      @media all and (min-width: 767px) and (max-width: 1280px) {
        .contact-page-container .main-container .text-form {
          width: 30%; } }
      @media (max-width: 767px) {
        .contact-page-container .main-container .text-form {
          margin-right: 0;
          margin-top:  3 0.75em; } }
      .contact-page-container .main-container .text-form .title {
        font-size: 1.875em;
        font-weight: 700;
        margin-top: 3.3em;
        user-select: none; }
        @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
          .contact-page-container .main-container .text-form .title {
            margin-bottom: 0.75em;
            margin-top: 1.5em; } }
        @media (min-width: 320px) and (max-width: 568px), (max-width: 767px) and (orientation: landscape) {
          .contact-page-container .main-container .text-form .title {
            margin: 1.25em 0 1em 0; } }
        @media (max-width: 767px) {
          .contact-page-container .main-container .text-form .title {
            font-size: 4.7em;
            margin-top: 2.5em;
            margin-bottom: 0.6em; } }
        @media all and (min-width: 767px) and (max-width: 1280px) {
          .contact-page-container .main-container .text-form .title {
            font-size: 2.5em; } }
      .contact-page-container .main-container .text-form .description {
        max-width: 30em;
        font-size: 1.25em;
        line-height: 1.75em;
        font-weight: 300;
        margin: 0;
        user-select: none; }
        @media (max-width: 767px) {
          .contact-page-container .main-container .text-form .description {
            font-size: 3.5em;
            line-height: 1.5; } }
        @media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
          .contact-page-container .main-container .text-form .description {
            margin: 0; } }
        @media all and (min-width: 767px) and (max-width: 1280px) {
          .contact-page-container .main-container .text-form .description {
            font-size: 2em;
            line-height: 1.5em; } }
        .contact-page-container .main-container .text-form .description a {
          color: #67A5BA; }
