@font-face {
  font-family: 'icomoon';
  src:  url('./icons.eot');
  src:  url('./icons.eot') format('embedded-opentype'),
    url('./icons.ttf') format('truetype'),
    url('./icons.woff') format('woff'),
    url('./icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e906";
  color: #fff;
}
.icon-success {
  color: white;
  background: #7EC9BA;
  border-radius: 1em;
  font-size: 1.6em;
  font-weight: 700;
  @media (max-width: 767px){
    font-size: 4em;
  }
}
.icon-success:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e901";
}
.icon-github:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e905";
}
