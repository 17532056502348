.background-container{
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    &.active {
        opacity: 1;
    }
    &-0{
        background-color: #71AFBF;
        background-image: url("../../assets/images/NY.jpg");
    }
    &-1{
        background: #fff;
    }
    &-2{
        background-color: #71AFBF;
        background-image: url("../../assets/images/buenos_aires.jpg");
    }
    &-3{
        background: #71AFBF;
    }
    &-4{
        background: #fff;
    }
    &-0, &-2{
        background-repeat: no-repeat;
        background-size: cover;
    }
}