@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700");

html {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: calc((1vh + 1vw) / 2);
}
body {
  margin: 0;
  padding: 0;
  overscroll-behavior: contain;
}
