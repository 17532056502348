.footer-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow: visible;
	justify-self: flex-end;
	@media (max-width: 767px) {
		margin-top: 8em;
	}
	.footer-icons {
		margin-top: 5em;
		width: 30em;
		align-self: center;
		@media (max-width: 767px) and (orientation: landscape) {
			display: none;
		}
	}
	.copyright {
		pointer-events: none;
		display: flex;
		justify-content: center;
		color: #595959;
		margin-top: 3.75em;
		padding: 2.5em 0 2.5em 0;
		user-select: none;
		text-align: center;
		@media (max-width: 767px) {
			font-size: 2em;
			padding: 2em 0;
		}
	}	
}
