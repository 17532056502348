.locations-container {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	margin-top: 2em;
	@media (max-width: 767px) {
		font-size: 2em;
		margin-bottom: 15em;
	}
	.title-locations {
		font-size: 2.5em;
		font-weight: 700;
		color: #595955;
		margin-top: 0;
		user-select: none;
	}
	.address {
		color: #595955;
		font-size: 1.5em;
		font-weight: 300;
		&:not(:last-child) {
			margin-bottom: 1em;
		}
	}
}
