$page-number-separation: calc(2em + 6%);

.description-page {
	display: flex;
	user-select: none;
	@media (min-width: 667px) and (max-width: 767px) {
		max-width: 80%;
	}
	@media (max-width: 767px) and (orientation: landscape) {
		max-width: 88%;
	}
    @media all and (min-width: 767px) and(max-width: 1280px) {
		max-width: 80%;
	}
	@media (max-width: 425px) and (min-height: 800px) {
		font-size: 0.9em;
	}
	.titles-container {
		display: flex;
		flex-direction: column;
		margin-top: 6vh;
		white-space: pre;
		@media (max-width: 767px) {
			margin-top: 3vh;
		}
		.main-title {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			font-size: 5em;
			font-weight: 700;
			line-height: 1.25em;
			color: #fff;
			white-space: pre-line;
			margin: 0;
			@media (min-width: 1280px) {
				max-width: 80%;
			}
			@media (max-width: 767px) and (orientation: landscape) {
				max-width: 100%;
			} 

			@media (max-width: 767px) and (orientation: portrait) {
				font-size: 6em;
				line-height: 1.3em;
				min-width: 300px;
			}
			@media all and (min-width: 767px) and(max-width: 1280px) {
				font-size: 6em;
			}
			.main-text{
				@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
					width: 90%;
				}
			}
		}
		.titles-divider {
			height: 0;
			border-top: 1.2px solid #fff;
			margin: 5vh 0;
			padding: 0;
            width: 7em;
            
			//LANDSCAPE 5/5S & 6/7/8
			@media (min-width: 320px) and (max-width: 568px),
				(max-width: 767px) and (orientation: landscape) {
				margin: 3.25em 0;
			}
			@media (max-width: 767px) {
				margin: 4vh 0;
				width: 22em;
            }
            @media all and (min-width: 767px) and(max-width: 1280px) {
				width: 26em;
			}
		}
		.subtext-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			white-space: pre-wrap;
			font-size: 1.875em;
			line-height: 1.75em;
			font-weight: 300;
			margin: 0;
			@media (max-width: 767px) {
				font-size: 4em;
				line-height: 1.4em;
			}
			@media (min-width: 1280px) {
				max-width: 80%;
			}
			//LANDSCAPE 5/5S & 6/7/8
			@media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
				padding-right: 8vw;
			}
			//LANDSCAPE 6/7/8
			@media (max-width: 767px) and (orientation: landscape) {
				padding-right: 5vw;
			}
			@media all and (min-width: 767px) and(max-width: 1280px) {
				font-size: 3em;
			}
		}
	}
}
