.form-container {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	margin-top: 5.7em;

	//LANDSCAPE 5/5S & 6/7/
	@media all and (min-width: 667px) and(max-width: 1280px) {
		margin-top: 7.6em;
	}
	@media (max-width: 767px) {
		margin-top: 6.2em;
		width: 100%;
	}
	@media (max-width: 767px) and (orientation: landscape) {
		top: 11.2em;
		right: 4.6em;
		font-size: 1em;
		width: 91%;
	}
	@media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
		margin-top: 7.25em;
		width: 90%;
	}
	@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
		margin-top: 7.25em;
	}
	.send-overlay {
		position: absolute;
		top: 22em;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 767px) {
			bottom: 6em;
		}

		.sending-step {
			display: flex;
			flex-direction: column;
			align-items: center;
			.spinner {
				margin-bottom: 1em;
			}
			span {
				font-size: 1.25em;
				color: #67a5ba;
				@media (max-width: 767px) {
					font-size: 3em;
				}
			}
		}

		.sent-step {
			display: flex;
			flex-direction: column;
			align-items: center;
			.success-icon-container {
				margin-bottom: 0.5em;
			}
			.success-text {
				font-size: 1.25em;
				color: #7ec9ba;
				margin-bottom: 1em;
				@media (max-width: 767px) {
					font-size: 3em;
				}
			}
		}
	}
}
