.contact-form {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	&.blur {
		opacity: 0.85;
		filter: blur(3px);
		pointer-events: none;
		.form-button {
			background-color: transparent;
			color: #67a5ba;
			border: none;
		}
	}
	@media (max-width: 767px) {
		margin-bottom: 7vh;
	}
	.material-input {
		display: flex;
		input,
		.textarea-content {
			flex-grow: 1;
			border: none;
			outline: none;
			font-size: 1.25em;
			font-weight: 300;
			color: #595959;
			font-family: "Roboto Condensed", sans-serif;
			background: none;
			border-bottom: 1px solid #595959;
			margin: 0 0 4rem;
			@media (max-width: 767px) {
				font-size: 3.5em;
				margin: 0 0 7rem;
			}
		}
		input {
			padding: 0.5rem 0;
			height: 1em;
			border-radius: 0;
		}
		.textarea-content {
			height: 7em;
			resize: none;
			padding: 0.5em 0;
			@media (max-width: 767px) {
				height: 6em;
				border-radius: 0;
			}
		}
	}
	.button-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		@media (max-width: 767px) {
			margin-top: 7.25em;
			justify-content: center;
		}
		.form-button {
			width: 6em;
			height: 2.5em;
			font-size: 1.5em;
			font-weight: 300;
			letter-spacing: 1px;
			@media (max-width: 350px) and (orientation: portrait) {
				width: 10em;
				height: 2.2em;
				font-size: 4.5em;
			}
			@media (max-width: 767px) {
				height: 2.5em;
				font-size: 3.5em;
			}
			@media all and (min-width: 767px) and(max-width: 1280px) {
				width: 10em;
				height: 3em;
				font-size: 1.725em;
			}
		}
	}
	input,
	.textarea-content {
		&:focus,
		&:hover,
		&:active {
			outline: none;
			border: none;
			border-bottom: 1px solid #67a5ba;
		}
	}
}
