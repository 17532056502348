.App {
  text-align: left;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
}
.intro-background {
  position: absolute;
  z-index: 200;
  background: #67A5BA;
  animation: fadeDown 800ms linear 3200ms forwards;
  opacity: 1;
  height: 100vh;
  width: 100%;
  pointer-events: none;
}

@keyframes fadeDown {
  100%{
    opacity: 0;
  }
}