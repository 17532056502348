.arrow-container {
	transition: background-color 1000ms ease-in-out;
	height: 3.75em;
	width: 3.75em;
	border-radius: 50%;
	background-color: #67A5BA;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	pointer-events: all;
}
.icon-arrow {
	font-size: 1.5em;
	transform: translateY(10%);
	transition: transform 300ms ease-in-out;
	height: 1.25em;
	&.inverted {
		transform: rotate(180deg) translateY(10%);
	}
}
.icon-arrow:before {
	transition: color 500ms ease-in-out;
	content: "\e906";
	color: #fff;
}
