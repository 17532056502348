.logo {
	cursor: pointer;
	transform: scale(5);
	@media (max-width: 667px) {
		transform: scale(3);
	}
	transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms;

	&-entered {
		transform: scale(1);
	}

	.b-logo {
		transform: translateX(45%);
		fill-opacity: 0;
		transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
		stroke: #fff;
		stroke-width: 0.5px;
		stroke-dasharray:1932 1934;
		stroke-dashoffset:1933;
		&-entered {
			fill-opacity: 1;
			transform: translateX(0);
		}
		animation: draw_border 3500ms ease-in, gradientFill 600ms linear 1000ms;
	}
	
	.bluealba-word{
		opacity: 0;
		transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
		&-entered {
			opacity: 1;
		}
	}
}

@keyframes draw_border{
	0%{
		stroke-dashoffset: 1933;
	}

	100%{ 
		stroke-dashoffset: 0;
	}
}

@keyframes gradientFill{
    0%{
        fill-opacity: 0;
        fill: #fff;
    }
    100%{
        fill-opacity: 1;
        fill: #fff;
    }
  }
