@media (min-width: 768px) {
	.icons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 2.625em;
		.icon-container{
			width: 2.625em;
			height: 3.5em;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (max-width: 767px) and (orientation: landscape) {
				justify-content: space-between;
				margin: 2em 0;
			}
			.icon-link {
				margin: 0.875em 0;
				transition: 0.4s;
				@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
					margin: 3em 0;
				}
				&:hover {
					margin: 0.3125em 0;
				}
				.icon-img {
					font-size: 2em;
					transition: 0.4s;
					&:hover {
						font-size: 3.125em;
					}

					@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
						font-size: 3.5em; 
					}
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.icons-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-self: flex-end;
		justify-content: space-between;
		height: 10em;
		width: 100%;

		.icon-link {
			margin: 0 0.875em;
			transition: 0.4s;
			&:hover {
				margin: 0 0.3125em;
			}
			.icon-img {
				font-size: 3em;
				transition: 0.4s;
				&:hover {
					font-size: 4em;
				}
				@media (max-width: 767px) and (orientation: portrait) {
					font-size: 4em;
				}
			}
		}
	}
}
@media (max-width: 767px) and (orientation: landscape) {
	.icons-container {
		flex-direction: column;
		align-self: center;
		.icon-container{
			margin: 2.5em 0;
			.icon-img {
				font-size: 4.5em;
			}
		}
	}	
}