* {
	outline: none;
}
a {
	text-decoration: none;
}
.fixed-container {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	box-sizing: border-box;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.page-container {
		display: flex;
		margin-top: 16vh;
		margin-left: 6vh;
		@media (max-width: 767px) {
			margin-left: 4vh;
		}
		.number-page {
			font-size: 1.25em;
			font-weight: 700;
			display: inline-block;
			transition: color 100ms ease-in-out;
			@media (min-width: 768px) and (max-width: 1280px) {
				font-size: 2.25em;
			}
			@media (max-width: 767px) {
				font-size: 3.5em;
			}
			@media (max-width: 767px) and (orientation: landscape) {
				margin-left: 0.4em;
			}
		}
		.page-line {
			border-top: 1px solid;
			height: 1px;
			align-self: center;
			margin-left: 0.5em;
			width: 5em;
			@media (min-width: 768px) and (max-width: 1280px) {
				width: 7em;
			}
			@media (max-width: 767px) {
				width: 14em;
			}
		}
	}
	.bar-icons-container {
		position: fixed;
		pointer-events: all;
		@media (min-width: 768px) {
			right: 4.5em;
			top: 10em;
		}
		@media (max-width: 767px) {
			display: flex;
			justify-content: center;
			width: 100%;
			bottom: 2em;
			>div {
				width: 30em;
			}
		}
		@media(min-height:1024px)and(max-height:1366px){
			top: 12.5em;
		}
		@media (max-width: 767px) and (orientation: landscape) {
			width: 6em;
			right: 0;
			top: 32%;
			transform: translate(-50%, -50%);
		}
	}
	.arrow-line-container {
		position: fixed;
		right: 4em;
		bottom: 3.75em;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 60%;
		justify-content: flex-end;
		@media (min-width: 320px) and (max-width: 568px), (max-width: 767px) and (orientation: landscape) {
			display: none;
		}
		@media (max-height: 500px), (max-width: 767px) {
			display: none;
		}
		.vertical-line {
			transition: color 100ms ease-in-out;
			width: 0;
			border-left: 1px solid;
			height: 20em;
			margin-bottom: 1.25em;
			@media (max-height: 590px), (max-width: 767px) {
				display: none;
			}
		}
	}

	&-0 {
		.number-page {
			color: #fff;
		}
		.icon-link {
			color: #fff;
		}
		.vertical-line.vertical-line {
			border-left-color: #fff;
		}
		.page-line.page-line {
			border-top-color: #fff;
		}
	}

	&-1 {
		.number-page {
			color: #595959;
		}
		.icon-link {
			color: #595959;
		}
		.vertical-line.vertical-line {
			border-left-color: #595959;
		}
		.app-header {
			color: #67a5ba;
		}
		.page-line.page-line {
			border-top-color: #595959;
		}
	}
	&-2 {
		.number-page {
			color: #fff;
		}
		.icon-link {
			color: #fff;
		}
		.vertical-line.vertical-line {
			border-left-color: #fff;
		}
		.page-line.page-line {
			border-top-color: #fff;
		}
	}
	&-3 {
		.arrow-container {
			background-color: #fff;
		}
		.icon-arrow:before {
			color: #67a5ba;
		}
		.number-page {
			color: #fff;
		}
		.icon-link {
			color: #fff;
		}
		.vertical-line.vertical-line {
			border-left-color: #fff;
		}
		.page-line.page-line {
			border-top-color: #fff;
		}
	}
	&-4 {
		.number-page {
			color: #595959;
		}
		.icon-link {
			color: #595959;
		}
		.vertical-line.vertical-line {
			border-left-color: #595959;
		}
		.page-line.page-line {
			border-top-color: #595959;
		}
		.icon-link {
			color: #595959;
		}
		@media (max-width: 767px) and (orientation: portrait) {
			.bar-icons-container {
				display: none;
			}
		}
	}
}
	
