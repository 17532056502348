.app-header {
	transform: translateY(-50%);
	&-entering {
		top: 50vh;
	}
	width: 100%;
	display: flex;
	justify-content: center;
	color: #fff;
	position: fixed;
	top: 5vh;
	transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 1200ms;
	align-items: center;
	height: 11vh;
	z-index: 1000;
	svg {
		height: 2.5em;
		@media (max-width: 767px) {
			height: 3.5em;
		}
	}
	@media (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
		.app-header {
			margin-top: 3.5em;
		}
		svg {
			height: 4.5em;
		}
	}
	&.scrolled {
		background: #ffffff;
	}
}
