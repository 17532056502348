.dynamic-page {
	width: 100vw;
	height: 100vh;
	padding: 16vh calc(6vh + 8em) 0 calc(6vh + 8em); 
	box-sizing: border-box;
	@media (min-width: 767px) and(max-width: 1280px) {
		padding: 16vh 6vh 0 6vh;
	}
	@media (max-width: 767px) {
		padding: 16vh 4vh 0 4vh;	
	}	
	@media (max-width: 767px) and (orientation: landscape) {
		padding: 16vh 6vh 0 6vh;
	}
	@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
		padding: 16vh 4vh 0 4vh;	
	}
	.current-section {
		font-size: 1.25em;
		font-weight: 700;
		user-select: none;
		margin: 0;
		@media all and (min-width: 767px) and (max-width: 1280px) {
			font-size: 2.25em;
			margin-left: 5.1em;
		}
		@media (max-width: 767px) {
			margin-left: 5.7em;
			font-size: 3.5em;
			margin-top: 0;
		}
		@media (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
			margin-top: 0;
		}
	}
		.titles-container {
			@media (max-width: 767px) and (orientation: landscape) {
				margin-top: 5vh;
			}
		}
		.subtext{
			@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
				width: 90%;
			}
		}
	&-0 {
		background-repeat: no-repeat;
		background-size: cover;
		pointer-events: none;
		.titles-container {
			color: #fff;
		}
		.current-section {
			color: #fff;
		}
		.line-container {
			color: #fff;
		}
		.main-text{
			@media (min-width: 1600px) {
				width: 66%;	
			}
			@media all and (min-width: 767px) and (max-width: 1500px) {
				width: 66%;
			}
		}
	}
	&-1 {
		pointer-events: none;
		.titles-container {
			.main-title {
				color: #67a5ba;
			}
			.subtext-container {
				color: #595959;
			}
		}

		.current-section {
			color: #595959;
		}

		.line-container {
			color: #595959;
		}
		.titles-divider {
			border-top: 1.2px solid #595959 !important;
		}
	}
	&-2 {
		pointer-events: none;
		background-repeat: no-repeat;
		background-size: cover;
		.main-title{
			@media all and (min-width: 767px) and(max-width: 1280px) {
				max-width: 85%;
			}
		}
		.main-title, .subtext-container{
			@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
				min-width: 280px;
			}
		}
		.titles-container {
			color: #fff;

		}
		.current-section {
			color: #fff;
		}
		.line-container {
			color: #fff;
		}
		.main-text{
			@media (min-width: 1600px) {
				width: 90%;	
			}
		}
	}
	&-3 {
		pointer-events: none;
		.titles-container {
			color: #fff;
		}
		.current-section {
			color: #fff;
		}
		.line-container {
			color: #fff;
		}
		.subtext-container{
			@media (min-width: 320px) and (max-width: 350px) and (orientation: portrait) {
				min-width: 280px;
			}
		}
	}
	&-4 {
		.current-section,
		.icons-container span,
		a {
			color: #595959;
		}
		@media (max-width: 767px) {
			height: auto;
		}
	}
}
